<template>
  <div id="container">

    <section id="mypage">
      <div class="wrap">

        <form @submit="update">

          <div class="cts_box">
            <h2 class="sub_ttl"><span>プロフィールの編集</span></h2>
            <p class="lead_txt" id="form-top">
              入力内容を修正する場合は、該当する項目をクリックして正しい内容を再度入力してください。<br>
              <span class="blue bold">「*」のついている箇所は必須項目です。</span>
            </p>

            <div class="msg_box error" v-if="errors">
              <h3>ご入力内容にエラー項目があります</h3>
              <p>エラー個所は赤く表示され、エラー理由がすぐ下に赤字で示されます。<br>お手数ですが、エラー項目を修正してからボタンを押してください。</p>
            </div>

            <div class="input_set name">
              <div class="label">おなまえ<span>*</span></div>
              <div class="input_fields flex">
                <div class="field">
                  <div class="label">姓</div>
                  <input type="text" name="last_name" maxlength="16" placeholder="例：山田" v-model="current_user.last_name"
                         @input="current_user.last_name = replaceHalfToFull(current_user.last_name)"
                         v-bind:class="{error: validation_errors.last_name}">
                  <ValidationErrors :errors="validation_errors.last_name" v-if="validation_errors.last_name"/>
                </div>
                <div class="field">
                  <div class="label">名</div>
                  <input type="text" name="first_name" maxlength="16" placeholder="例：太郎" v-model="current_user.first_name"
                         @input="current_user.first_name = replaceHalfToFull(current_user.first_name)"
                         v-bind:class="{error: validation_errors.first_name}">
                  <ValidationErrors :errors="validation_errors.first_name" v-if="validation_errors.first_name"/>
                </div>
              </div>
            </div>

            <div class="input_set kana">
              <div class="label">フリガナ<span>*</span></div>
              <div class="input_fields flex">
                <div class="field">
                  <div class="label">姓（全角カナ）</div>
                  <input type="text" name="last_name_kana" maxlength="16" placeholder="例：ヤマダ" v-model="current_user.last_name_kana"
                         @input="current_user.last_name_kana = replaceHiraToKana(current_user.last_name_kana)"
                         v-bind:class="{error: validation_errors.last_name_kana}">
                  <ValidationErrors :errors="validation_errors.last_name_kana" v-if="validation_errors.last_name_kana"/>
                </div>
                <div class="field">
                  <div class="label">名（全角カナ）</div>
                  <input type="text" name="first_name_kana"  maxlength="16" placeholder="例：タロウ" v-model="current_user.first_name_kana"
                         @input="current_user.first_name_kana = replaceHiraToKana(current_user.first_name_kana)"
                         v-bind:class="{error: validation_errors.first_name_kana}">
                  <ValidationErrors :errors="validation_errors.first_name_kana"
                                    v-if="validation_errors.first_name_kana"/>
                </div>
              </div>
            </div>

            <div class="input_set">
              <div class="label">性別<span>*</span></div>
              <div class="radio">
                <input id="radio01" type="radio" name="gender" v-model="current_user.gender" value="male">
                <label for="radio01">男性</label>
                <input id="radio02" type="radio" name="gender" v-model="current_user.gender" value="female">
                <label for="radio02">女性</label>
                <input id="radio03" type="radio" name="gender" v-model="current_user.gender" value="secret">
                <label for="radio03">その他</label>
              </div>
              <ValidationErrors :errors="validation_errors.gender" v-if="validation_errors.gender"/>
            </div>

            <div class="input_set birth">
              <div class="label">生年月日<span>*</span></div>
              <p>すべての欄に半角数字でご入力下さい。</p>
              <div class="input_fields flex">
                <div class="field">
                  <input type="text" name="birthday_year" placeholder="年" v-model="current_user.birthday_year"
                         @input="current_user.birthday_year = replaceFullToHalf(current_user.birthday_year)"
                         v-bind:class="{error: validation_errors.birthday_year}">
                  <ValidationErrors :errors="validation_errors.birthday_year" v-if="validation_errors.birthday_year"/>
                </div>
                <div class="field">
                  <input type="text" name="birthday_month" placeholder="年" v-model="current_user.birthday_month"
                         @input="current_user.birthday_month = replaceFullToHalf(current_user.birthday_month)"
                         v-bind:class="{error: validation_errors.birthday_month}">
                  <ValidationErrors :errors="validation_errors.birthday_month" v-if="validation_errors.birthday_month"/>
                </div>
                <div class="field">
                  <input type="text" name="birthday_day" placeholder="年" v-model="current_user.birthday_day"
                         @input="current_user.birthday_day = replaceFullToHalf(current_user.birthday_day)"
                         v-bind:class="{error: validation_errors.birthday_day}">
                  <ValidationErrors :errors="validation_errors.birthday_day" v-if="validation_errors.birthday_day"/>
                </div>
              </div>
            </div>

            <template v-if="current_user.rank === 'rank_student_month'">
            <div class="input_set birth">
              <div class="label">卒業見込み年/月<span>*</span></div>
              <div class="input_fields flex">
                <div class="field">
                  <input type="text" v-model="current_user.student_graduation_date_year"
                         @input="current_user.student_graduation_date_year = replaceFullToHalf(current_user.student_graduation_date_year)"
                         v-bind:class="{error: validation_errors.student_graduation_date_year}" placeholder="年">
                  <validation-errors :errors="validation_errors.student_graduation_date_year"
                                     v-if="validation_errors.student_graduation_date_year"></validation-errors>
                </div>
                <div class="field">
                  <input type="text" v-model="current_user.student_graduation_date_month"
                         @input="current_user.student_graduation_date_month = replaceFullToHalf(current_user.student_graduation_date_month)"
                         v-bind:class="{error: validation_errors.student_graduation_date_month}" placeholder="月">
                  <validation-errors :errors="validation_errors.student_graduation_date_month"
                                     v-if="validation_errors.student_graduation_date_month"></validation-errors>
                </div>
              </div>
            </div>

            <div class="input_set email">
              <div class="label">学校で発行されたメールアドレス<span>*</span><span v-if="current_user.student_not_email">（学生証登録とのいずれか必須）</span></div>
              <p>学生科入塾資格の確認のため、学校が発行した ac.jp, edu.jpなどの電子メールアドレスを入力してください。</p>
              <input type="text" class="email" v-bind:class="{error: validation_errors.student_email}"
                     v-model="current_user.student_email" placeholder="hukugan@millioneyes.ac.jp">
              <ValidationErrors :errors="validation_errors.student_email"
                                v-if="validation_errors.student_email"></ValidationErrors>
            </div>

            <div class="input_set card_select">
              <div class="checkbox">
                <input id="check03" type="checkbox" v-model="current_user.student_not_email" value="1"
                       v-on:change="studentNotEmailClicked()">
                <label for="check03" class="bold blue">
                  学校で発行したメールアドレスをお持ちでない場合は、学生証の登録により認証ができます。<br>
                  こちらにチェックをいれて学生証をアップロードしてください。
                </label>
              </div>
            </div>

            <div class="input_set card" v-if="current_user.student_not_email">
              <div class="label">学生証の登録<span>*</span>（学校発行のメールアドレスとのいずれか必須）</div>
              <p>お手持ちの学生証の画像（jpgまたはpng形式）をアップロードしてください。</p>
              <div class="card_img">
                <div v-if="current_user.student_card_image_url">
                  <img v-bind:src="current_user.student_card_image_url" id="student_card_image" alt="">
                </div>
                <div v-else>
                  <img src="/common/images/card_sample.png" alt="アップロードした画像が表示されます">
                </div>
              </div>
              <div class="btn_set flex">
                <div class="btn">
                  <label for="student_card_image1">画像をアップロード</label>
                  <input id="student_card_image1" type="file" accept="image/png,image/jpeg" v-on:change="studentCardImageSelected('student_card_image1')"/>
                </div>
                <div class="btn sp">
                  <label for="student_card_image2"><span class="icon_camera">カメラで撮影する</span></label>
                  <input id="student_card_image2" type="file" accept="image/png,image/jpeg" capture="camera" v-on:change="studentCardImageSelected('student_card_image2')"/>
                </div>
              </div>
              <!--
              <button type="button" class="btn photo_delete" v-on:click="studentCardImageDeleted()">削除</button>
              -->
              <ValidationErrors :errors="validation_errors.student_card_image"
                                v-if="validation_errors.student_card_image"></ValidationErrors>
            </div>
            </template>

            <div class="input_set email">
              <div class="label">連絡先メールアドレス<span>*</span></div>
              <input type="email" name="email" v-model="current_user.email"
                     @input="current_user.email = replaceFullToHalf(current_user.email)"
                     v-bind:class="{error: validation_errors.email}">
              <ValidationErrors :errors="validation_errors.email" v-if="validation_errors.email"/>
            </div>

            <div class="input_set email" v-if="false">
              <div class="label">第二メールアドレス（オプション）<span></span></div>
              <input type="email" name="email2" v-model="current_user.email2" v-bind:class="{error: validation_errors.email2}"
                     placeholder="連絡先メールアドレスとは異なるアドレスへのメール送付をご希望の場合のみ記入">
              <ValidationErrors :errors="validation_errors.email" v-if="validation_errors.email"/>
            </div>

            <div class="input_set phone">
              <div class="label">連絡先電話番号<span>*</span></div>
              <p>（半角数字で日中連絡の取れるお電話番号をご入力下さい。）</p>
              <input type="tel" name="tel" v-model="current_user.phone_number"
                     @input="current_user.phone_number = replaceFullToHalf(current_user.phone_number)"
                     v-bind:class="{error: validation_errors.phone_number}" placeholder="例：09012345678"
                     value="09012345678">
              <ValidationErrors :errors="validation_errors.phone_number" v-if="validation_errors.phone_number"/>
            </div>

            <div class="reg_set">
              <div class="input_set addr">
                <div class="label">郵便番号<span>*</span></div>
                <p>（半角数字）</p>
                <div class="input_fields flex">
                  <div class="field">
                    <input type="text" v-model="current_user.zip" v-bind:class="{error: validation_errors.zip}"
                           @input="current_user.zip = replaceFullToHalf(current_user.zip)"
                           name="zip"
                           placeholder="例：100-1234">
                    <validation-errors :errors="validation_errors.zip" v-if="validation_errors.zip"></validation-errors>
                  </div>
                  <div class="field">
                    <button type="button" class="ajaxzip3" v-on:click="zipSearch">郵便番号から住所を取得</button>
                  </div>
                </div>
              </div>

              <div class="input_set addr">
                <div class="label">都道府県名<span>*</span></div>
                <p>（選択して下さい ※海外にお住まいの方は「日本国外に居住」を選択のうえ国内の送付先住所を「送付先住所指定フォーム」にご入力ください）</p>
                <PrefectureSelect :prefecture="current_user.prefecture" name="prefecture"
                                  v-bind:class="{error: validation_errors.prefecture}"
                                  v-on:changed="prefectureChanged"/>
                <validation-errors :errors="validation_errors.prefecture"
                                   v-if="validation_errors.prefecture"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">市区町村<span>*</span></div>
                <input type="text" v-model="current_user.address1" v-bind:class="{error: validation_errors.address1}"
                       @input="current_user.address1 = replaceHalfToFull(current_user.address1)"
                       name="address1"
                       placeholder="例：新宿区">
                <validation-errors :errors="validation_errors.address1"
                                   v-if="validation_errors.address1"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">地名・番地<span>*</span></div>
                <input type="text" v-model="current_user.address2" v-bind:class="{error: validation_errors.address2}"
                       @input="current_user.address2 = replaceHalfToFull(current_user.address2)"
                       name="address2"
                       class="address" placeholder="例：３丁目１３番地">
                <validation-errors :errors="validation_errors.address2"
                                   v-if="validation_errors.address2"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">建物名・部屋番号<span></span></div>
                <input type="text" v-model="current_user.address3" v-bind:class="{error: validation_errors.address3}"
                       @input="current_user.address3 = replaceHalfToFull(current_user.address3)"
                       name="address3"
                       class="addr4" placeholder="例：複眼ビル１１１号室">
                <validation-errors :errors="validation_errors.address3"
                                   v-if="validation_errors.address3"></validation-errors>
              </div>

              <div class="input_set">
                <div class="label">パスワード</div>
                <p>（8文字以上の半角英数記号）</p>
                <div class="pswd_box flex">
                  <input v-bind:type="password_type ? 'text' : 'password'" v-model="current_user.password"
                         @input="current_user.password = replaceFullToHalf(current_user.password)"
                         name="password"
                         v-bind:class="{error: validation_errors.password}" class="password" placeholder="8文字以上">
                  <div class="checkbox">
                    <input id="check01" type="checkbox" v-model="password_type" value="1">
                    <label for="check01">入力内容を表示</label>
                  </div>
                </div>
                <validation-errors :errors="validation_errors.password"
                                   v-if="validation_errors.password"></validation-errors>
              </div>

              <div class="input_set">
                <div class="label">パスワード（確認のためにもう一度入力）</div>
                <p>（8文字以上の半角英数記号）</p>
                <div class="pswd_box flex">
                  <input v-bind:type="password_confirmation_type ? 'text' : 'password'" v-model="current_user.password_confirmation"
                         @input="current_user.password_confirmation = replaceFullToHalf(current_user.password_confirmation)"
                         name="password_confirmation"
                         v-bind:class="{error: validation_errors.password_confirmation}" class="password" placeholder="8文字以上">
                  <div class="checkbox">
                    <input id="check02" type="checkbox" v-model="password_confirmation_type" value="1">
                    <label for="check02">入力内容を表示</label>
                  </div>
                </div>
                <validation-errors :errors="validation_errors.password_confirmation"
                                   v-if="validation_errors.password_confirmation"></validation-errors>
              </div>

              <div class="input_set">
                <div class="checkbox">
                  <input id="option_addr" type="checkbox" v-model="current_user.enable_primary_address" value="1">
                  <label for="option_addr" class="blue bold">郵便物を別住所で受け取りたい方、海外在住で日本の住所がある方はチェックを入れて、郵便物等の送付先住所指定フォームに別住所を指定してください</label>
                  <p class="pl25">※送付先は日本国内に限ります。</p>
                </div>
              </div>
            </div>

            <div class="reg_set option" v-show="current_user.enable_primary_address">
              <h3 class="min_ttl">郵便物等の送付先住所指定（任意：登録ご住所と同じ場合は入力は不要です）</h3>
              <p class="reg_lead">
                オンラインショップの商品や複眼経済塾からの各種お知らせなど、塾生登録住所以外への送付を希望する場合はご入力ください。<br>塾生登録住所以外で郵便物などを送付する住所のご希望がない場合は入力をスキップしてください。<br><br>
              </p>

              <div class="gray_box">
                <div class="input_set addr">
                  <div class="label">郵便番号</div>
                  <p>（半角数字）</p>
                  <div class="input_fields flex">
                    <div class="field">
                      <input type="text" v-model="current_user.opt_zip"
                             v-bind:class="{error: validation_errors.opt_zip}" name="opt_zip"
                             @input="current_user.opt_zip = replaceFullToHalf(current_user.opt_zip)"
                             placeholder="例：100-1234">
                      <validation-errors :errors="validation_errors.opt_zip"
                                         v-if="validation_errors.opt_zip"></validation-errors>
                    </div>
                    <div class="field">
                      <button type="button" class="ajaxzip3" v-on:click="optZipSearch">郵便番号から住所を取得</button>
                    </div>
                  </div>
                </div>

                <div class="input_set addr">
                  <div class="label">都道府県名</div>
                  <p>（選択して下さい ※海外にお住まいの方は「日本国外に居住」を選択のうえ国内の送付先住所を「送付先住所指定フォーム」にご入力ください）</p>

                  <PrefectureSelect :prefecture="current_user.opt_prefecture" name="opt_prefecture"
                                    v-bind:class="{error: validation_errors.opt_prefecture}"
                                    v-on:changed="optPrefectureChanged"/>
                  <validation-errors :errors="validation_errors.opt_prefecture"
                                     v-if="validation_errors.opt_prefecture"></validation-errors>

                </div>
                <div class="input_set addr">
                  <div class="label">市区町村</div>
                  <input type="text" v-model="current_user.opt_address1"
                         @input="current_user.opt_address1 = replaceHalfToFull(current_user.opt_address1)"
                         name="opt_address1"
                         v-bind:class="{error: validation_errors.opt_address1}" placeholder="例：新宿区">
                  <validation-errors :errors="validation_errors.opt_address1"
                                     v-if="validation_errors.opt_address1"></validation-errors>
                </div>

                <div class="input_set addr">
                  <div class="label">地名・番地</div>
                  <input type="text" v-model="current_user.opt_address2"
                         @input="current_user.opt_address2 = replaceHalfToFull(current_user.opt_address2)"
                         v-bind:class="{error: validation_errors.opt_address2}" class="address"
                         name="opt_address2"
                         placeholder="例：３丁目１３番地">
                  <validation-errors :errors="validation_errors.opt_address2"
                                     v-if="validation_errors.opt_address2"></validation-errors>
                </div>

                <div class="input_set addr">
                  <div class="label">建物名・部屋番号</div>
                  <input type="text" v-model="current_user.opt_address3"
                         @input="current_user.opt_address3 = replaceHalfToFull(current_user.opt_address3)"
                         name="opt_address3"
                         v-bind:class="{error: validation_errors.opt_address3}" class="addr4" placeholder="例：複眼ビル１１１号室">
                  <validation-errors :errors="validation_errors.opt_address3"
                                     v-if="validation_errors.opt_address3"></validation-errors>
                </div>
              </div>

            </div>

            <div class="button_wrap">
              <p>すべての項目を確認の上でこちらのボタンを押してください。</p>
              <input type="submit" class="submit arrow light large" value="プロフィールを編集して保存">
            </div>
            <div class="button_wrap">
              <router-link :to="{name: 'UsersCustomerAccount'}" class="submit arrow back skeleton light large">
                マイページに戻る
              </router-link>
            </div>
          </div>

        </form>

      </div><!--//.wrap-->
    </section>

  </div>
</template>

<script>
import Libraries from '@/components/Libraries'
import ValidationErrors from '@/components/ValidationErrors'
import PrefectureSelect from "@/components/PrefectureSelect";

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors,
    PrefectureSelect,
  },
  data() {
    return {
      errors: false,
      validation_errors: {},
      password_type: false,
      password_confirmation_type: false,
    }
  },
  methods: {
    prefectureChanged(prefecture) {
      this.current_user.prefecture = prefecture
      //this.$forceUpdate()
    },
    optPrefectureChanged(prefecture) {
      this.current_user.opt_prefecture = prefecture
      //this.$forceUpdate()
    },
    zipSearch() {
      if (this.current_user.zip) {
        this.addressSearch(this.current_user.zip)
            .then(data => {
              this.current_user.prefecture = data.pref
              this.current_user.address1 = data.address
              this.$forceUpdate()
            })
      }
    },
    optZipSearch() {
      if (this.current_user.opt_zip) {
        this.addressSearch(this.current_user.opt_zip).then(data => {
          this.current_user.opt_prefecture = data.pref
          this.current_user.opt_address1 = data.address
          this.$forceUpdate()
        })
      }
    },

    studentNotEmailClicked() {
    },

    studentCardImageDeleted() {
      this.current_user.student_card_image_url = `${this.env.api_base_url}card_sample.png`
      this.current_user.remove_student_card_image = true
    },

    studentCardImageSelected(id) {
      const params = new FormData();
      const fileSelectDom = document.getElementById(id)
      params.append('file', fileSelectDom.files[0])
      //params.append('token', this.$route.params.token)
      this.validation_errors.student_card_image = null
      this.current_user.student_card_image_cache = null
      //this.current_user.student_card_image_url = null
      this.current_user.remove_student_card_image = false
      this.axios
          .post(`${this.env.api_base_url}users/picture.json`, params)
          .then(response => {
            this.current_user.student_card_image_cache = response.data.cache_name
            this.current_user.student_card_image_url = response.data.url
          })
          .catch((response) => {
            if (response.data && response.data.errors) {
              // console.log(error.response.data);
              this.validation_errors.student_card_image = response.data.errors
            }
          });
    },

    update(event) {
      event.preventDefault()

      // 学生メアドがあれば学生証はなし
      //if (this.current_user.rank === 'rank_student_month' && this.current_user.student_email) {
      //  this.current_user.student_card_image = this.current_user.student_card_image_url = null
      // this.current_user.remove_student_card_image = true
      //  this.current_user.student_not_email = false
      //}

      this.startAjax()
      this.axios
          .post(`${this.env.api_base_url}users/update.json`, {
            user: this.current_user,
          })
          .then(response => {
            //console.log(response.data)
            this.saveSession(response.data.user);
            this.$router.push({name: 'UsersCustomerAccount'})
          })
          .catch(error => {
            this.errors = true
            if (error.response.data) {
              if (error.response.data.errors) {
                this.errors = true
                this.scrollTo('#form-top')
              }
              if (error.response.data.validation_errors) {
                this.validation_errors = error.response.data.validation_errors
              }
            }
          })
          .finally(() => {
            this.finishAjax()
            // alert('更新しました');
          })
    },
  }
}
</script>
